let dynamicControllers = ["part_ui_input", "part_ui_select", "part_ui_wsw"];
let dynamicActions = [[`a[href^="#"]`, "click->lib#anchor"], [`.part_ui_btn, .part_ui_link`, "click->lib#ripple"]];
let locomotive;
let swup;

const application = Stimulus.Application.start(document.documentElement, {
    controllerAttribute: "data-controller",
    actionAttribute: "data-action",
    targetAttribute: "data-target"
});

importScript.used = [];
importScript.loaded = [];

if ('serviceWorker' in navigator && location.protocol === "https:") {
    window.addEventListener('load', function () {
        if (!document.documentElement.classList.contains("no-sw")) {
            navigator.serviceWorker.register('/sw.js').catch(function (e) {
                console.error('Error during service worker registration:', e);
            });
        } else {
            navigator.serviceWorker.getRegistrations().then(function (registrations) {
                if (registrations.length > 0) {
                    for (let registration of registrations) {
                        registration.unregister()
                    }
                }
            });
        }
    });
}

(function locomotiveScroll() {
    bodyLoaded(function() {
        if(!document.documentElement.classList.contains("ie")) {
            locomotive = new LocomotiveScroll({
                el: document.getElementById('layout_scroll'),
                smooth: true,
                getSpeed: true,
                getDirection: true,
                inertia: 0.9
                // smoothMobile: true
            });

            setTimeout(function () {
                locomotive.update();
            }, 1000);
        }
    });
})();

if (document.body.classList.contains("body--preload")) {
    cssLoaded(function () {
        document.body.classList.remove("body--preload");
        setTimeout(function () {
            document.body.classList.add("body--loaded");
        }, 300);
    });
}

if (typeof WebFontConfig === "undefined" && document.querySelector("[data-loadcss]") !== null) {
    if (typeof document.fonts !== "undefined") {
        document.fonts.ready.then(function () {
            document.documentElement.classList.add("wf-active");
        });

        setTimeout(() => {
            if (!document.documentElement.classList.contains("wf-active")) {
                document.documentElement.classList.add("wf-active");
            }
        }, 500);
    } else {
        document.documentElement.classList.add("wf-active");
    }
}

if (document.createElement('picture').toString().indexOf("HTMLPictureElement") === -1 && !document.documentElement.classList.contains("ie")) {
    importScript(cdnjs.picturefill);
}

if (typeof document.documentElement.style.grid !== 'string') {
    document.documentElement.classList.add("no-grid");
}
